import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"contacts"} />
      <Helmet>
        <title>Rubber Revolution Tires</title>
        <meta
          name={"description"}
          content={"Drive the Extra Mile with Confidence!"}
        />
        <meta property={"og:title"} content={"Rubber Revolution Tires"} />
        <meta
          property={"og:description"}
          content={"Drive the Extra Mile with Confidence!"}
        />
        <meta
          property={"og:image"}
          content={"https://plexiastark.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://plexiastark.com/img/2761318.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://plexiastark.com/img/2761318.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://plexiastark.com/img/2761318.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://plexiastark.com/img/2761318.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://plexiastark.com/img/2761318.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://plexiastark.com/img/2761318.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="140px 0"
        sm-padding="40px 0 40px 0"
        background="linear-gradient(0deg,rgba(0,0,0,.2) 0%,rgba(0,0,0,.2) 100%),--color-dark url(https://plexiastark.com/img/6.jpg) 50% 15%/cover"
        color="--light"
        font="--base"
      >
        <Override slot="SectionContent" sm-align-items="center" />
        <Box width="100%">
          <Text
            as="h1"
            margin="0 0 96px 0"
            text-align="center"
            letter-spacing="50px"
            text-transform="uppercase"
            font="100 132px/1.2 --fontFamily-sans"
            lg-font="200 42px/1.2 --fontFamily-sans"
            lg-letter-spacing="20px"
          >
            Get in Touch
          </Text>
        </Box>
        <Box
          display="flex"
          margin="-16px -16px -16px -16px"
          flex-wrap="wrap"
          width="100%"
        >
          <Box
            display="flex"
            padding="16px 16px 16px 16px"
            width="25%"
            lg-width="50%"
            sm-width="100%"
          >
            <Box display="flex" font="--lead">
              <Text margin="0px">
                Ready to elevate your driving experience? Visit us, give us a
                call, or drop an email. We're here to answer any questions and
                get you back on the road, safely and swiftly.
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            padding="16px 16px 16px 16px"
            width="25%"
            lg-width="50%"
            sm-width="100%"
          >
            <Box display="flex">
              <Text margin="0px">+61 2 4949 7761</Text>
            </Box>
          </Box>
          <Box
            display="flex"
            padding="16px 16px 16px 16px"
            width="25%"
            lg-width="50%"
            sm-width="100%"
          >
            <Box display="flex">
              <Text margin="0px">contact@plexiastark.com</Text>
            </Box>
          </Box>
          <Box
            display="flex"
            padding="16px 16px 16px 16px"
            width="25%"
            lg-width="50%"
            sm-width="100%"
          >
            <Box display="flex">
              <Text margin="0px">
                10 Regent St, New Lambton NSW 2305, Australia
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
